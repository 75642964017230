import React from 'react';
import { ColDef } from '@ag-grid-community/core';
import { ActionRenderer } from '../cellRenderers/Action';

import { SuggestedAudience, HeaderLabels } from 'models/AudienceSuggestions';
import {
  AffinityHeader,
  OverlapHeader,
  ReachHeader,
} from '../headerRenderers/ColumnHeader';
import { SelectAllCheckbox } from '@amzn/unified-data-table-components/core';
import { simplifyDisplayValue } from '../../../utils/audienceSuggestionUtils';
import {
  ACTION_COL_ID,
  AFFINITY_COL_ID,
  CATEGORY_COL_ID,
  CATEGORY_FILTERS,
  NAME_COL_ID,
  OVERLAP_COL_ID,
  REACH_COL_ID,
} from '../../../constants/suggestion';
import { AudienceDataClient } from '../../../api';

const cellStyle = {
  height: '100%',
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'flex-start',
};

const textCellStyle = {
  ...cellStyle,
  'padding-top': '7px',
};

export const getSuggestionsColumnDefs = (
  headerLabels: HeaderLabels,
  audienceDataClient: AudienceDataClient,
  useLegacy: boolean
) =>
  [
    {
      colId: 'suggestions-bulk-include-checkbox',
      checkboxSelection: true,
      width: 52,
      minWidth: 52,
      maxWidth: 52,
      sortable: false,
      headerComponentFramework: SelectAllCheckbox,
    },
    {
      colId: ACTION_COL_ID,
      headerName: '',
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      valueGetter: ({ data }: { data: SuggestedAudience }) => {
        if (!data) return undefined;
        return data;
      },
      cellRendererFramework: ActionRenderer,
      cellRendererParams: {
        audienceDataClient,
        useLegacy,
      },
    },
    {
      colId: CATEGORY_COL_ID,
      headerName: headerLabels.category,
      field: CATEGORY_COL_ID,
      width: 90,
      minWidth: 70,
      cellStyle: textCellStyle,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: CATEGORY_FILTERS,
      },
      wrapText: true,
    },
    {
      colId: NAME_COL_ID,
      headerName: headerLabels.name,
      width: 200,
      minWidth: 150,
      valueGetter: (params): string => params?.data?.name,
      cellStyle: textCellStyle,
      wrapText: true,
    },
    {
      colId: OVERLAP_COL_ID,
      headerName: headerLabels.overlap,
      minWidth: 60,
      width: 80,
      cellRenderer: 'NumberCellRenderer',
      valueGetter: params => params?.data?.insightMetrics?.overlapPercentage,
      cellStyle,
      cellRendererParams: {
        getFormattedValue: (value: number): string => {
          if (value === null || value === undefined) return '';
          return `${value}%`;
        },
      },
      headerComponentParams: {
        label: <OverlapHeader />,
      },
    },
    {
      colId: REACH_COL_ID,
      headerName: headerLabels.reach,
      minWidth: 100,
      width: 120,
      cellRenderer: 'NumberCellRenderer',
      valueGetter: params => {
        if (params?.data?.forecast?.dailyReach)
          return [
            params.data.forecast.dailyReach.lowerBound,
            params.data.forecast.dailyReach.upperBound,
          ];
        return [];
      },
      cellStyle,
      cellRendererParams: {
        getFormattedValue: (value: Array<number>): string => {
          if (value?.length >= 2)
            return `${simplifyDisplayValue(value[0])} - ${simplifyDisplayValue(
              value[1]
            )}`;

          return '';
        },
      },
      headerComponentParams: {
        label: <ReachHeader />,
      },
      comparator: (valueA, valueB): number => {
        const sumA = valueA[0] + valueA[1];
        const sumB = valueB[0] + valueB[1];
        if (sumA < sumB) return -1;
        if (sumA > sumB) return 1;
        return 0;
      },
    },
    {
      colId: AFFINITY_COL_ID,
      headerName: headerLabels.affinity,
      minWidth: 60,
      width: 80,
      cellRenderer: 'NumberCellRenderer',
      valueGetter: (params): string => params?.data?.insightMetrics?.affinity,
      cellStyle,
      cellRendererParams: {
        getFormattedValue: (value: number): string => {
          if (value === null || value === undefined) return '';
          return `${value}x`;
        },
      },
      headerComponentParams: {
        label: <AffinityHeader />,
      },
    },
  ] as ColDef[];
