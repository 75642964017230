import { Fee } from "src/model/Fee.model";
import { EMPTY_FEE } from "src/constants";

export interface ManagedServiceFeeProps {
  managedServiceFee: Fee | null | undefined,
}

export interface ManagedServiceFeeState extends ManagedServiceFeeProps {
  setManagedServiceFee: (fee: Fee) => void,
  invalidateManagedServiceFee: () => void,
  setManagedServiceLoadingState: (isLoading: boolean) => void
}

export const createManagedServiceFeeSlice = (set): ManagedServiceFeeState => ({
  managedServiceFee: null,
  setManagedServiceFee: (fee: Fee) => set(() => ({managedServiceFee: fee})),
  setManagedServiceLoadingState:  (isLoading: boolean) =>
    set((state) => ({managedServiceFee: {...state.managedServiceFee, isLoading}})),
  invalidateManagedServiceFee: () => set(() => ({managedServiceFee: EMPTY_FEE})),
})