import styled from 'styled-components';
import { Button, Icon, TextButton } from '@amzn/storm-ui';

export const SubProgramNode = styled.div<{ index: number }>`
  display: flex;
  margin-left: -24px;
  min-height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-top: ${({ index }) => (index > 0 ? '1px solid #ddd' : 0)};
  padding-right: 20px;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export const SubProgramExpandButton = styled(Button)`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  &:hover {
    background-color: transparent;
  }
`;

export const ExpandableIcon = styled(Icon)`
  display: inline;
  margin-right: ${({ theme }) => theme.spacing.micro};
  svg {
    padding-bottom: 1px;
    path {
      fill: black;
    }
  }
  padding-left: 2px;
  font-size: 12px;
`;

export const PanelViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 8px 4px 4px;
  height: 100%;
`;

export const ModeDetailWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 15px 0px 0px 15px;
  overflow: auto;
`;

export const DeviceListContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow: auto;
`;

export const SearchInputContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.base};
  margin-right: 15px;
  overflow: visible;
  -webkit-box-flex: 0;
  flex-grow: 0;
`;
export const SearchBarContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
  padding: 0px;
  font-weight: initial;
  display: inline-flex;
  flex-flow: column nowrap;
  -webkit-box-pack: start;
  place-content: stretch flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
`;

export const ButtonContainer = styled.div`
  padding-top: 0px;
`;

export const BackButton = styled(TextButton)`
  display: inline-flex;
  justify-content: start;
  margin-right: -24px;
  padding-left: 24px;
  padding-bottom: ${({ theme }) => theme.spacing.base};
  font-size: 13px;
  line-height: 1.2;
`;

export const BackIcon = styled(Icon)`
  display: inline;
  margin-right: ${({ theme }) => theme.spacing.micro};
  svg {
    padding-bottom: 1px;
    path {
      fill: ${({ theme }) => theme.palette.blue[700]};
    }
  }
  padding-left: 2px;
  font-size: 13px;
`;

export const InitialLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  & > * {
    margin: 8px 0;
  }
`;

export const NoSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
