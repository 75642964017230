import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import {
  UnifiedDataTable,
  DataViewStateInSessionStorage,
  TableState,
  TextCellRenderer,
  NumberCellRenderer,
  FilterModel,
} from '@amzn/unified-data-table-components/core';
import {
  GridOptions,
  GridReadyEvent,
  ColumnApi,
  GridApi,
  SortChangedEvent,
  ColumnState,
  FilterChangedEvent,
} from '@ag-grid-community/core';

import { NoSuggestions } from './noSuggestions/NoSuggestions';

import { TranslationContext } from '../../state/translationContext';
import {
  SUGGESTION_HEADER_AUDIENCE_NAME,
  SUGGESTION_HEADER_CATEGORY,
  SUGGESTION_HEADER_AFFINITY,
  SUGGESTION_HEADER_OVERLAP,
  SUGGESTION_HEADER_REACH,
  SUGGESTED_AUDIENCE,
  HIDE_SUGGESTIONS,
  SHOW_SUGGESTIONS,
} from '../../constants/translations';

import { useVisibility } from '../../hooks/useVisibility';
import { useAudienceTargeting } from '../../state/useAudienceTargeting';

import { getSuggestionsColumnDefs } from './columns/columnDefinition';

import '@amzn/unified-data-table-components/styles/css/udc-theme.css';
import {
  SuggestedAudience,
  TargetingExpression,
} from '../../models/AudienceSuggestions';
import { Footer } from './footer/Footer';
import { TextButton, Text } from '@amzn/storm-ui';
import { HeaderContainer } from './AudienceSuggestions.styled';
import { Header } from './header/Header';
import { INCLUDE } from '../../constants';
import { AudienceDataClient, SuggestionsDataClient } from '../../api';
import { SuggestionsDataSource } from './dataSource/SuggestionsDataSource';
import { ApiData } from '../../models';
import { isEqual } from 'lodash-es';
import {
  HALF_TABLE_HEIGHT,
  MARGIN_BETWEEN_TABLES,
  MAX_SUGGESTIONS,
} from '../../constants/suggestion';
import { useResizeObservable } from '../../hooks/useResizeObservable';
import { useRefreshSuggestionsTable } from './useRefreshDataTable';

interface AudienceSuggestionsProps {
  /**
   * Callback method to get the height of the table and dynamically update it.
   */
  getTableHeight?: (
    isSuggestionTableExpanded: boolean,
    calculateSuggestionTableHeight?: boolean
  ) => string;
  setShowSuggestions: Function;
  showSuggestions: boolean;
  apiData: ApiData;
  audienceDataClient: AudienceDataClient;
  useLegacy: boolean;
  locale?: string;
}

export const AudienceSuggestions = ({
  showSuggestions,
  setShowSuggestions,
  getTableHeight,
  apiData,
  audienceDataClient,
  useLegacy,
  locale,
}: AudienceSuggestionsProps) => {
  const TABLE_ID = 'audience-suggestions-table';

  const { state } = useAudienceTargeting();

  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [gridApi, setGridApi] = useState<GridApi>();

  const [tableHeight, setTableHeight] = useState<string>(
    `${HALF_TABLE_HEIGHT}px`
  );
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const dataViewStateInSessionStorage = new DataViewStateInSessionStorage();
  const currentState: any = dataViewStateInSessionStorage.getCurrentState(
    TABLE_ID
  );

  const stateInSessionStorage = new DataViewStateInSessionStorage<TableState>();

  const ref = useRef<HTMLDivElement>(null);
  const isTableVisible = useVisibility(ref);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  const getTranslation = useContext(TranslationContext);

  const headerLabels = {
    name: getTranslation(SUGGESTION_HEADER_AUDIENCE_NAME),
    category: getTranslation(SUGGESTION_HEADER_CATEGORY),
    overlap: getTranslation(SUGGESTION_HEADER_OVERLAP),
    reach: getTranslation(SUGGESTION_HEADER_REACH),
    affinity: getTranslation(SUGGESTION_HEADER_AFFINITY),
  };
  const columnDefs = getSuggestionsColumnDefs(
    headerLabels,
    audienceDataClient,
    useLegacy
  );

  const isInclude = useMemo(
    () => state.selectedGroup.targetingGroupType === INCLUDE,
    [state.selectedGroup.targetingGroupType]
  );

  const gridOptions: GridOptions = {
    rowModelType: 'serverSide',
    serverSideStoreType: 'full',
    columnDefs,
    components: {
      TextCellRenderer,
      NumberCellRenderer,
    },
    rowSelection: 'multiple',
    onSelectionChanged: event => {
      setSelectedRows(event.api.getSelectedRows());
    },
    isRowSelectable: rowNode => rowNode.data && rowNode.data.id && isInclude,
    frameworkComponents: {
      noSuggestionsOverlay: NoSuggestions,
    },
    defaultColDef: {
      sortable: true,
    },
    animateRows: true,
    getRowNodeId: (data: SuggestedAudience): string => data.id,
    serverSideFilteringAlwaysResets: true,
  };

  const suggestionsDataClient = useMemo(
    () => apiData && new SuggestionsDataClient(apiData),
    [apiData]
  );

  const dataSource = useMemo(
    () =>
      suggestionsDataClient &&
      new SuggestionsDataSource(
        suggestionsDataClient,
        setSucceeded,
        setErrorMessage
      ),
    [suggestionsDataClient]
  );

  const refreshDataTable = (
    targetingExpression: TargetingExpression[],
    forceRefresh?: boolean
  ) => {
    if (
      !forceRefresh &&
      isEqual(suggestionsDataClient.targetingExpression, targetingExpression)
    )
      return;
    suggestionsDataClient.targetingExpression = targetingExpression;
    if (!gridApi) return;
    gridApi.deselectAll();
    gridApi.refreshServerSideStore({ purge: true });
  };

  const [audienceIncompatible, noSegmentIncluded] = useRefreshSuggestionsTable(
    refreshDataTable,
    suggestionsDataClient
  );

  useEffect(() => {
    if (!gridApi || !dataSource) return;
    gridApi.setServerSideDatasource(dataSource);
  }, [gridApi, dataSource]);

  useEffect(() => {
    if (isTableVisible) columnApi?.autoSizeAllColumns();
  }, [isTableVisible, columnApi]);

  const tHeight = getTableHeight?.(showSuggestions, true);
  useEffect(() => {
    if (tHeight) setTableHeight(tHeight);
  }, [tHeight]);

  // Refresh data table to update the message when audience is incompatible or audience are not included
  useEffect(() => {
    if (audienceIncompatible || noSegmentIncluded) refreshDataTable([], true);
  }, [audienceIncompatible, noSegmentIncluded]);

  const callback = useCallback(
    (mutationList: any) => {
      if (!getTableHeight) {
        const getElementHeight = (elementId: string) =>
          mutationList[0].target.querySelector(`#${elementId}`)?.offsetHeight ||
          0;
        // get header height by the sum of suggestion title div, table header and margin (7px).
        // suggestion title div, Margin (7px) gets divided between discovery and suggestions table hence diving by 2
        const header =
          getElementHeight('suggestions-header') / 2 +
          getElementHeight('suggestions-header-panel') +
          MARGIN_BETWEEN_TABLES / 2;
        const footer = getElementHeight('suggestions-footer-panel');

        const computedTableHeight = `${HALF_TABLE_HEIGHT - header - footer}px`;
        if (tableHeight !== computedTableHeight)
          setTableHeight(computedTableHeight);
      }
    },
    [tableHeight, getTableHeight]
  );

  useResizeObservable<HTMLDivElement | null>(suggestionsRef.current, callback);

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api);
      setColumnApi(event.columnApi);
      event.api.setFilterModel(currentState.filterModel);
      const initialState = stateInSessionStorage.getCurrentState(TABLE_ID);
      if (initialState?.columnState)
        event.columnApi.applyColumnState({
          state: initialState.columnState
            .filter(
              columnState =>
                columnState.sort !== null && columnState.sort !== undefined
            )
            .map(columnState => ({
              colId: columnState.colId,
              sort: columnState.sort,
              sortIndex: columnState.sortIndex,
            })),
          defaultState: { sort: null },
        });
    },
    [setColumnApi, setGridApi]
  );

  const onSortChanged = (event: SortChangedEvent): void => {
    const columnState: ColumnState[] = event.columnApi.getColumnState();
    stateInSessionStorage.updateTableState(TABLE_ID, {
      columnState,
    });
  };

  const onFilterChanged = (event: FilterChangedEvent): void => {
    const filterModel: FilterModel = event.api.getFilterModel() as FilterModel;
    stateInSessionStorage.updateTableState(TABLE_ID, {
      filterModel,
    });
    const height = getTableHeight?.(showSuggestions, true);
    if (height) setTableHeight(height);
  };

  return (
    <div ref={suggestionsRef}>
      <HeaderContainer id="suggestions-header">
        <Text type="p" fontSize="medium">
          {getTranslation(SUGGESTED_AUDIENCE)}
        </Text>
        <TextButton onClick={() => setShowSuggestions(!showSuggestions)}>
          {showSuggestions
            ? getTranslation(HIDE_SUGGESTIONS)
            : getTranslation(SHOW_SUGGESTIONS)}
        </TextButton>
      </HeaderContainer>
      <div
        data-testid={'suggestions-table-component'}
        ref={ref}
        style={{ display: showSuggestions ? 'block' : 'none' }}
      >
        <UnifiedDataTable
          onGridReady={onGridReady}
          uidPrefix={TABLE_ID}
          gridOptions={gridOptions}
          headerPanel={
            <Header
              selectedRows={selectedRows}
              audienceDataClient={audienceDataClient}
              useLegacy={useLegacy}
            />
          }
          footerPanel={
            <Footer pageSizes={[MAX_SUGGESTIONS]} hidePageStatus={!succeeded} />
          }
          noRowsOverlayComponent="noSuggestionsOverlay"
          maxTableHeight="none"
          locale={locale ?? 'en-US'}
          noRowsOverlayComponentParams={{
            noSegmentIncluded,
            succeeded,
            audienceIncompatible,
            errorMessage,
          }}
          tableHeight={tableHeight}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
        />
      </div>
    </div>
  );
};
