export function getKebabCaseLocale(locale: string) {
    return locale.replace(/_/g, '-');
}

export function getNumberFormatter(locale: string | string[],
                                   options?: Intl.NumberFormatOptions | undefined): Intl.NumberFormat | undefined {
    if (!Intl) {
        return undefined;
    }

    const kebabCaseLocale = (Array.isArray(locale) ? locale : [locale]).map(getKebabCaseLocale);

    try {
        return new Intl.NumberFormat(kebabCaseLocale, options);
    } catch (error) {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#exceptions
        if (error instanceof RangeError) {
            if (options?.maximumFractionDigits || options?.minimumFractionDigits) {
                return getNumberFormatter(locale, {
                    ...(options ?? {}),
                    maximumFractionDigits: undefined,
                    minimumFractionDigits: undefined,
                })
            }
            // @TODO: handle RangeError
        }
        // @TOTO: handle TypeError
    }

    return new Intl.NumberFormat(kebabCaseLocale);
}

export type GetOptions<T> = (arg: T) => (Intl.NumberFormatOptions | undefined);

export function getNumberFormat<T>(getOptions?: GetOptions<T> | Intl.NumberFormatOptions) {
    return ({ value, locale, ...restProps }: {
        value: number;
        locale: string | string[],
    } & T) => {
        const numberFormat = getNumberFormatter(locale, typeof getOptions === 'function' ? getOptions(restProps as T) : getOptions);

        if (!numberFormat) {
            return `${value}`;
        }

        return numberFormat.format(value);
    };
}

export interface FormatNumberOptions {
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
}

/**
 * @param {number} value The value to format.
 * @param {string} locale A string with a BCP 47 language tag, or an array of such strings.
 * @param {number} maximumFractionDigits The maximum number of fraction digits to use.
 * @param {number} minimumFractionDigits The minimum number of fraction digits to use.
 */
const formatNumber = getNumberFormat<FormatNumberOptions>(({
                                                               maximumFractionDigits,
                                                               minimumFractionDigits,
                                                           }) => ({
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits,
}));

export default formatNumber;