import { LineItemV1 } from '@amzn/d16g-state-management-interfaces';
import { Common } from '@mfe/state-management-interfaces';
import { includes } from 'lodash-es';

// @ts-ignore
export enum ImpressionSupplyTypes { // eslint-disable-line no-shadow
  'VIDEO' = 'VIDEO',
  'WEB' = 'WEB',
  'STV' = 'STV',
  'STREAMING_AUDIO' = 'STREAMING_AUDIO',
  'PVA' = 'PVA',
  'OTT_VIDEO' = 'OTT_VIDEO',
}

export enum AdTypes { // eslint-disable-line no-shadow
  'OTT_VIDEO' = 'OTT_VIDEO',
  'AAP_VIDEO_CPM' = 'AAP_VIDEO_CPM',
  'KINDLE' = 'KINDLE',
}

export const VideoSubImpressionSupplyTypes = [
  ImpressionSupplyTypes.STV,
  ImpressionSupplyTypes.PVA,
  ImpressionSupplyTypes.OTT_VIDEO,
];

export const STV_SUPPLY_SOURCE_IDS = [4106, 611, 4032]; // list all STV supply source ids
export const TWITCH_SUPPLY_SOURCE_IDS = [650, 651]; // list of all Twitch supply source ids
export const PVA_SUPPLY_SOURCE_ID = [623];
export const OTHER_SUPPLY_SOURCE_ID = [610];

export const SELECTED_SOURCES = 'SELECTED_SOURCES';

export function getImpressionSupplyType(
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel>
): ImpressionSupplyTypes {
  if (!lineItemState?.type) {
    console.error('No line item type found');
    return undefined;
  }

  const lineItemType = lineItemState.type;

  if (lineItemType === AdTypes.OTT_VIDEO) {
    return ImpressionSupplyTypes.OTT_VIDEO;
  }

  if (lineItemType === AdTypes.AAP_VIDEO_CPM) {
    if (hasPVASource(lineItemState)) return ImpressionSupplyTypes.PVA;
    if (hasSTVSources(lineItemState)) return ImpressionSupplyTypes.STV;

    return ImpressionSupplyTypes.VIDEO;
  }

  return ImpressionSupplyTypes.WEB;
}

export function getActiveImpressionSupplyTypes(
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel>
): ImpressionSupplyTypes[] {
  const activeSuppyTypes = [];

  const lineItemType = lineItemState.type;

  switch (lineItemType) {
    case AdTypes.OTT_VIDEO:
      activeSuppyTypes.push(ImpressionSupplyTypes.OTT_VIDEO);
    case AdTypes.AAP_VIDEO_CPM:
      if (hasSTVSources(lineItemState))
        activeSuppyTypes.push(ImpressionSupplyTypes.STV);
      if (hasNonSTVSources(lineItemState))
        activeSuppyTypes.push(ImpressionSupplyTypes.VIDEO);
      if (hasPVASource(lineItemState))
        activeSuppyTypes.push(ImpressionSupplyTypes.PVA);
      break;
    default:
      activeSuppyTypes.push(ImpressionSupplyTypes.WEB);
  }

  return activeSuppyTypes;
}

function hasSTVSources(
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel>
) {
  const supplySourceType =
    lineItemState?.targetingDetails?.supplySourceDetails?.supplySourceType;

  if (supplySourceType === SELECTED_SOURCES) {
    if (lineItemState?.supplySourcesTree_NON_HOUSE_VIDEO?.some(isSTVSource))
      return true;
    else if (
      lineItemState?.targetingDetails?.supplySourceDetails?.supplySources?.some(
        isSTVSource
      )
    )
      return true;
  } else {
    const includeOttSupply =
      // @ts-ignore
      lineItemState?.externalSupplySourceView?.includeOttSupply;

    const includeTwitchSelfServiceStandalone =
      // @ts-ignore
      lineItemState?.externalSupplySourceView
        ?.includeTwitchSelfServiceStandalone;
    // returns true if the STV or Twitch supply source checkbox is checked
    if (
      includeOttSupply ||
      includeTwitchSelfServiceStandalone ||
      hasDealSelected(lineItemState)
    )
      return true;
  }
  return false;
}

function hasPVASource(
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel>
) {
  return (
    lineItemState?.supplySourcesTree_NON_HOUSE_VIDEO?.some(isPVASource) ||
    lineItemState?.targetingDetails?.supplySourceDetails?.supplySources?.some(
      isPVASource
    )
  );
}

function hasNonSTVSources(
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel>
): boolean {
  const supplySourceType =
    lineItemState?.targetingDetails?.supplySourceDetails?.supplySourceType;

  if (
    lineItemState?.externalSupplySourceView?.supplySourcesConsolidated?.some(
      (source) => source.selected
    )
  )
    return true;
  else if (lineItemState?.externalSupplySourceView?.amazonOOSites) {
    const OOSites = lineItemState?.externalSupplySourceView?.amazonOOSites;
    if (Object.keys(OOSites).some((OOSite) => OOSites[OOSite])) return true;
  } else if (lineItemState?.externalSupplySourceView?.includeDirect)
    return true;
  else if (lineItemState?.externalSupplySourceView?.includeImdbTv) return true;
  else if (supplySourceType) {
    if (supplySourceType === SELECTED_SOURCES) {
      if (lineItemState?.supplySourcesTree_NON_HOUSE_VIDEO?.every(isSTVSource))
        return false;
      if (
        lineItemState?.targetingDetails?.supplySourceDetails?.supplySources?.every(
          isSTVSource
        )
      )
        return false;
      if (lineItemState?.supplySourcesTree_NON_HOUSE_VIDEO?.every(isPVASource))
        return false;
      if (
        lineItemState?.targetingDetails?.supplySourceDetails?.supplySources?.every(
          isPVASource
        )
      )
        return false;

      return true;
    }
    return true;
  }
}

export function hasDealSelected(
  lineItemState: Common.DeepPartial<LineItemV1.LineItemModel>
): boolean {
  return (
    lineItemState?.targetingDetails?.supplyPackageList?.length > 0 ||
    lineItemState?.dealSectionView?.deals?.length > 0
  );
}

function isSTVSource(source) {
  return (
    includes(TWITCH_SUPPLY_SOURCE_IDS, +source) ||
    includes(STV_SUPPLY_SOURCE_IDS, +source)
  );
}

function isPVASource(source) {
  return includes(PVA_SUPPLY_SOURCE_ID, +source);
}

export const ConvertAdTypeToFeeType = (adTypes: string[]) => {
  const feeTypes = new Set([]);
  adTypes.forEach((adType: string) => {
    switch (adType) {
      case AdTypes.AAP_VIDEO_CPM:
      case AdTypes.OTT_VIDEO:
        feeTypes.add(ImpressionSupplyTypes.VIDEO);
        feeTypes.add(ImpressionSupplyTypes.STV);
        feeTypes.add(ImpressionSupplyTypes.PVA);
        break;
      default:
        feeTypes.add(ImpressionSupplyTypes.WEB);
    }
  });
  return Array.from(feeTypes);
};
