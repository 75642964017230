import {Fee} from "src/model";

export interface CurrencyOfPreferenceFeeProps {
    currencyOfPreference: string | null | undefined,
    fxConversionRate: number | null | undefined,
    currencyOfMarketplace: string | null | undefined
    locale: string | null | undefined
}

export interface CurrencyOfPreferenceState extends CurrencyOfPreferenceFeeProps {
    setCurrencyOfPreference: (currencyOfPreference: string) => void,
    setFxConversionRate: (fxConversionRate: number) => void,
    setCurrencyOfMarketplace: (currencyOfMarketplace: string) => void
    setLocale: (locale: string) => void
}

export const createCurrencyOfPreferenceSlice = (set): CurrencyOfPreferenceState => ({
    currencyOfPreference: null,
    fxConversionRate: null,
    currencyOfMarketplace: null,
    locale: null,
    setCurrencyOfPreference: (cop: string) => set({currencyOfPreference: cop}),
    setFxConversionRate: (fxConversionRate: number) => set({fxConversionRate: fxConversionRate}),
    setCurrencyOfMarketplace: (currencyOfMarketplace: string) => set({currencyOfMarketplace: currencyOfMarketplace}),
    setLocale: (locale: string) => set({locale: locale})
})