import { useRef } from "react";
import { MISSING_FEE_STORE_CONTEXT } from "src/constants";
import { useStore } from "zustand";
import { formatPercentDisplayFee } from "src/utils/FeeUtil";
import { PricingApiHelper } from "src/utils/PricingApiHelper";

export default function useManagedServiceFee() {
  const feeStore = useRef(PricingApiHelper.getInstance().getFeeStore()).current;

  if (!feeStore) {
    throw new Error(MISSING_FEE_STORE_CONTEXT);
  }

  const observedManagedServiceFee =
    useStore(feeStore, (state) => state.managedServiceFee) || undefined;

  return {
    observedManagedServiceFee: formatPercentDisplayFee(observedManagedServiceFee)
  }

}