import { useContext } from 'react';
import { CustomElementSegment, DisplayFee } from "src/model";
import { FeeContext } from "src/context/FeeContext";
import { useStore } from "zustand";
import {
    EMPTY_DISPLAY_FEE,
    MISSING_FEE_STORE_CONTEXT,
} from "src/constants";
import { formatDisplayFee } from "src/utils/FeeUtil";
import { retrieveAllAudiences } from "src/store/selectors/selectors";

export default function useAudienceFee(audience?: CustomElementSegment) {
    const feeStore = useContext(FeeContext);

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    const observedAudienceFee: DisplayFee | undefined = useStore(feeStore, (state) => {
        if (!audience || !state) {
            return undefined;
        }
        const id = audience?.audienceId || audience?.canonicalId;

        const allAudiences = retrieveAllAudiences(state);

        return allAudiences.get(id) as unknown as DisplayFee;
    })

    const observeAudiencesForPricing = useStore(feeStore, (state) => state.observeAudiencesForPricing);

    return {
        observeAudiencesForPricing,
        observedAudienceFee: observedAudienceFee ? formatDisplayFee(observedAudienceFee) : EMPTY_DISPLAY_FEE
    };
}