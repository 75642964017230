import { useContext, useMemo } from 'react';
import { AudienceDisplayFee, CustomElementSegment, DisplayFee, Fee } from "src/model";
import { useStore } from "zustand";
import { FeeContext } from "src/context/FeeContext";
import {
    COMBINED_AUDIENCES_CATEGORY,
    MISSING_FEE_STORE_CONTEXT,
    THIRD_PARTY_CATEGORIES
} from "src/constants";
import { PricingEngineAudienceV2 } from "@amzn/d16g-pricing-engine-api-type-script-client/dist-types/models/models_0";
import { getEmptyAudienceFee, getMaxFeeReducer } from "src/utils/AudienceFeeUtil";
import { buildTotalFeeObject, formatDisplayFee } from "src/utils/FeeUtil";
import { retrieveAllAudiences } from "src/store/selectors/selectors";
import { useShallow } from "zustand/react/shallow";

const useMaxAudiencesFees = (audiences?: CustomElementSegment[] | undefined) => {
    const feeStore = useContext(FeeContext);

    if (!feeStore) {
        throw new Error(MISSING_FEE_STORE_CONTEXT);
    }

    const audienceDisplayFees: AudienceDisplayFee[] = useStore(feeStore, useShallow((state) => {
        if (!audiences || audiences.length === 0 || !state) {
            return [];
        }

        // todo: come back to figure out why this is necessary when no audiences should be in the store
        return audiences.filter(audience => audience !== undefined).map(audience => {

            const id = audience?.audienceId || audience?.canonicalId;

            const allAudiences = retrieveAllAudiences(state);
            if (allAudiences.has(id)) {
                return allAudiences.get(id) as unknown as AudienceDisplayFee;
            }

            // if the fee is not found, return an empty fee, assume it is loading
            return {
                ...getEmptyAudienceFee(true),
                audience: {
                    id: audience.audienceId ?? audience.canonicalId,
                    category: audience.category,
                    provider: audience.provider,
                } as PricingEngineAudienceV2
            }
        });
    }));

    const observedMaxFirstPartyFee: DisplayFee = useMemo(() => {
        // @ts-ignore
        let firstPartyFeeToDisplay = audienceDisplayFees.reduce((accumulator, fee) => {
            const category = fee.audience.category || "";
            if (!THIRD_PARTY_CATEGORIES.includes(category)) {
                if (category === COMBINED_AUDIENCES_CATEGORY) {
                    return getMaxFeeReducer(accumulator, fee.firstPartyFees ?? getEmptyAudienceFee(false));
                } else {
                    return getMaxFeeReducer(accumulator, fee);
                }
            }
            return accumulator;
        }, getEmptyAudienceFee(false, "0"));
        return formatDisplayFee(firstPartyFeeToDisplay);
    }, [JSON.stringify(audienceDisplayFees)])


    const observedMaxThirdPartyFee: DisplayFee = useMemo(() => {
        // @ts-ignore
        let thirdPartyFeeToDisplay = audienceDisplayFees.reduce((accumulator, fee) => {
            const category = fee.audience.category || "";
            if (THIRD_PARTY_CATEGORIES.includes(category) || category === COMBINED_AUDIENCES_CATEGORY) {
                if (category === COMBINED_AUDIENCES_CATEGORY) {
                    return getMaxFeeReducer(accumulator, fee.thirdPartyFees ?? getEmptyAudienceFee(false));
                } else {
                    return getMaxFeeReducer(accumulator, fee);
                }
            }
            return accumulator;
        }, getEmptyAudienceFee(false, "0"));
        return formatDisplayFee(thirdPartyFeeToDisplay);
    }, [JSON.stringify(audienceDisplayFees)])

    const observedTotalFee = useMemo(() => {
        const maxFirstPartyFeeValue = observedMaxFirstPartyFee?.value ?? 0;
        const maxThirdPartyFeeValue = observedMaxThirdPartyFee?.value ?? 0;
        const totalFeeValue = Number(maxFirstPartyFeeValue) + Number(maxThirdPartyFeeValue);
        const totalFee = buildTotalFeeObject(totalFeeValue, observedMaxFirstPartyFee, observedMaxThirdPartyFee);
        return formatDisplayFee(totalFee);
    }, [observedMaxFirstPartyFee, observedMaxThirdPartyFee]);

    return {
        observedMaxFirstPartyFee,
        observedMaxThirdPartyFee,
        observedTotalFee
    };
};

export default useMaxAudiencesFees;
