import { AnyFee, DisplayFee, Fee } from "src/model";
import { FeeStore } from "src/store/FeeStore";
import { PricingApiHelper } from "src/utils/PricingApiHelper";
import { ZERO_FEE } from "src/constants";
import { getNumberFormat } from "src/utils/currencyFormatUtils";

/**
 * Formats a Fee object for display purposes.
 *
 * @param {Fee} [fee=EMPTY_FEE] - The Fee object to be formatted.
 * @returns {DisplayFee} An object containing the formatted fee values, containing two formatted values, one that
 * accounts for currency of preference and one which uses the default currency.
 */
export const formatDisplayFee = (
    fee: AnyFee = ZERO_FEE,
): DisplayFee => {
    let copFormat: string = "--";
    let defaultFormat: string = "--";
    const feeStore: FeeStore = PricingApiHelper.getInstance().getFeeStore();
    const value = Number(fee?.value);

    if (feeStore && !isNaN(value)) {

        const currency = fee?.currency || feeStore.getState()?.currencyOfMarketplace;
        const locale = feeStore.getState()?.locale;

        if (currency) {
            defaultFormat = getFormattedCurrency(value, locale, currency);
        }

        const fxConversionRate = feeStore.getState()?.fxConversionRate;
        const currencyOfPreference = feeStore.getState()?.currencyOfPreference;
        if (fxConversionRate && currencyOfPreference) {
            const copValue = Number((value * fxConversionRate).toFixed(2));
            copFormat = getFormattedCurrency(copValue, locale, currencyOfPreference);
        }

    }

    return {
        ...fee,
        copDisplayValue: copFormat,
        displayValue: defaultFormat
    }
}

/**
 * Formats a fee value as a percentage for display purposes.
 *
 * @param {AnyFee} fee - The fee object to be formatted. If not provided, defaults to a zero fee.
 * @returns {DisplayFee} An object containing the formatted fee value and display value.
 *
 * @example
 * const fee = { value: '0.15' };
 * const displayFee = formatPercentDisplayFee(fee);
 * console.log(displayFee.value); // Output: '15.00'
 * console.log(displayFee.displayValue); // Output: '15.00%'
 */
export const formatPercentDisplayFee = (fee: AnyFee = ZERO_FEE): DisplayFee => {
    const PERCENT_FACTOR = 100;
    if (fee.value) {
        return {
            ...fee,
            value:  (Number(fee.value) * PERCENT_FACTOR).toFixed(2).toString(),
            displayValue: (Number(fee.value) * PERCENT_FACTOR).toFixed(2).toString() + '%'
        }
    } else {
        return {
            ...fee,
            value: '--',
            displayValue: '--%'
        }
    }
}

/**
 * Builds a Fee object representing the total fee based on the provided parameters.
 *
 * @param {number} totalFeeValue - The total fee value.
 * @param {Fee} observedMaxFirstPartyFee - The observed maximum first-party fee.
 * @param {Fee} observedMaxThirdPartyFee - The observed maximum third-party fee.
 * @returns {Fee} An object representing the total fee.
 */
export const buildTotalFeeObject = (
    totalFeeValue: number,
    observedMaxFirstPartyFee: Fee,
    observedMaxThirdPartyFee: Fee,
): Fee => {
    // @ts-ignore
    return {
        value: totalFeeValue.toString(),
        currency: observedMaxFirstPartyFee.currency || observedMaxThirdPartyFee.currency,
        range: {
            // @ts-ignore
            max: Math.max(Number(observedMaxFirstPartyFee.range?.max?.amount), Number(observedMaxThirdPartyFee.range?.max?.amount)).toFixed(2).toString(),
            // @ts-ignore
            min: Math.min(Number(observedMaxThirdPartyFee.range?.min?.amount), Number(observedMaxFirstPartyFee.range?.min?.amount)).toFixed(2).toString(),
        },
        isLoading: observedMaxFirstPartyFee.isLoading
    }
}

/**
 * Convert locale from en_US to en-US
 * @param {string} locale Locale (e.g. en_US)
 * @returns {string} locale with hyphen between first two letters
 * and last two letters
 */
export const getLocaleWithHyphen = (locale: string): string => {
    return locale.replace(/_/g, '-');
};

/**
 * Storm-UI format currency function
 * @param {string} locale Locale (e.g. en_US)
 * @returns {string} locale with hyphen between first two letters
 * and last two letters
 */
export const formatCurrency = getNumberFormat<{ currency: string }>(({ currency }) => ({
    style: 'currency',
    currencyDisplay: 'symbol',
    signDisplay: 'auto',
    currency,
}));

/**
 * Converts a currency value to locale-formatted currency
 *
 * @param {number} value currency number value (e.g. 25)
 * @param {string} locale Locale string (e.g. en-US)
 * @param {string} currency Currency string (e.g. USD)
 * @returns {string} formattedCurrency locale-formatted currency value (e.g. $25.00)
 */
export const getFormattedCurrency = (
    value: number,
    locale: string | null | undefined,
    currency: string,
): string => {
    if (!locale) {
        // @ts-ignore
        return value.toLocaleString(undefined, { style: 'currency', currency: currency })
    }

    // For locale 'en_US', international currency formatter doesn't recognize the locale
    // formattedLocale will convert it to en-US
    return formatCurrency({
        value,
        locale: getLocaleWithHyphen(locale),
        currency,
    });
};

