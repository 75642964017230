import { TreeNode } from '../data/TreeNode';
import { ApiData } from '../../../models';

export interface SearchBarProps {
  defaultPlaceholder: string;
  onSearchInputChange: (searchInput: string) => void;
}

export interface TechnologyTargetingProps {
  apiData: ApiData;
  locale?: string;
}

export interface PredicateListViewProps {
  apiData: ApiData;
}

export interface PredicateDetailsProps {
  id: string;
  name: string;
  description: string;
}

export interface PredicateProps {
  predicate: PredicateDetailsProps;
  setIsOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  onSave: (
    targetList: TreeNode[],
    excludeList: TreeNode[],
    detailedTargetedNodes: Set<string>,
    detailedExcludedNodes: Set<string>
  ) => void;
  initialTargetList: TreeNode[];
  initialExcludeList: TreeNode[];
  initialDetailedTargetedNodes: Set<string>;
  initialDetailedExcludedNodes: Set<string>;
  apiData: ApiData;
}

export interface PredicateValuePanelStylesProps {
  status: string;
  duration: number;
}

export interface PredicateGroupProps {
  type: string;
  emptyMessage: string;
  isSelected: boolean;
  onSelect: () => void;
  onRemoveAll: () => void;
  nodesList: TreeNode[];
  onRemoveItem: (node: TreeNode) => void;
}

export interface PredicateGroupsProps {
  predicate: PredicateDetailsProps;
  selectedGroup: GroupType;
  onSelectGroup: (group: GroupType) => void;
  targetList: TreeNode[];
  excludeList: TreeNode[];
  onRemoveAllTargets: () => void;
  onRemoveAllExcludes: () => void;
  onRemoveItem: (node: TreeNode) => void;
}

export interface NavPredicateProps {
  predicate: PredicateDetailsProps;
  apiData: ApiData;
}

export interface ModeProps {
  predicate: PredicateDetailsProps;
  rootPathId?: string;
  onTargetClick: (node: TreeNode) => void;
  detailedTargetedNodes: Set<string>;
  detailedExcludedNodes: Set<string>;
  targetList: TreeNode[];
  selectedGroup: GroupType;
  apiData: ApiData;
}

export interface TargetButtonProps {
  onClick: () => void;
  disabled: boolean;
  detailedTargetedNodes: Set<string>;
  detailedExcludedNodes: Set<string>;
  selectedGroup: GroupType;
  node: TreeNode;
}

export interface RemoveAllButtonProps {
  onClick: () => void;
  getTranslation: (key: string) => string;
}

export interface PredicateGroupItemProps {
  name: string;
  onClick: () => void;
}

export enum GroupType {
  Targeted = 'targeted',
  Excluded = 'excluded',
}

export enum DeviceTypeMode {
  Brand = 'BRAND',
  OS = 'OS',
}
