import {AudienceDisplayFee, ThirdPartyAudienceDisplayFee} from "src/model/Fee.model";
import { CustomElementSegment } from "src/model/AudienceGroup.model";
import {
    PricingEngineAudienceProvider
} from "@amzn/d16g-pricing-engine-api-type-script-client/dist-cjs/models/models_0";
import { THIRD_PARTY_CATEGORIES } from "src/constants/combined-audiences";
import { FeeTypes } from "src/utils/supplyTypeUtils";
import { calculateThirdPartyAudienceFee } from 'src/utils/ThirdPartyAudiencePricingUtil';

export interface AudienceFeeProps {
    audienceFeeIndex: Map<string, AudienceDisplayFee>,
    thirdPartyAudienceFeeIndex: Map<string, ThirdPartyAudienceDisplayFee>,
    thirdPartyImpressionFeeSupplyType: FeeTypes | null | undefined,
}

export interface AudienceFeeState extends AudienceFeeProps {
    updateAudienceFeeIndex: (audiencesFees: Map<string, AudienceDisplayFee>) => void,
    updateThirdPartyAudienceFeeIndex: (audiencesFees: Map<string, ThirdPartyAudienceDisplayFee>) => void,
    updateAudienceFromIndex: (audienceFee: AudienceDisplayFee) => void,
    observeAudiencesForPricing: (audiences: CustomElementSegment[]) => void,
    invalidateAudienceIndex: () => void,
    setAudienceFeeLoadingState: (isLoading: boolean) => void,
    updateThirdPartyImpressionSupplyType: (newImpressionSupplyType: FeeTypes) => void,
}
export const convertThirdPartyAudienceToPricedFee = (audience: CustomElementSegment, impressionSupplyType): [string, ThirdPartyAudienceDisplayFee] => {
    const id = audience.audienceId || audience.canonicalId;
    const thirdPartyAudienceDisplayFee = {
        value: null,
        currency: null,
        range: null,
        isLoading: true,
        audience: audience,
    };
    return [id, calculateThirdPartyAudienceFee(thirdPartyAudienceDisplayFee, impressionSupplyType)];
};

export const convertAudienceToEmptyFee = (audience: CustomElementSegment): [string, AudienceDisplayFee] => {
    const id = audience.audienceId || audience.canonicalId;
    const audienceDisplayFee: AudienceDisplayFee = {
        value: null,
        currency: null,
        range: null,
        audience: {
            id,
            provider: PricingEngineAudienceProvider.AMAZON_AUDIENCE,
            category: audience.category,
            subCategory: audience.subCategory || undefined,
            negativeTarget: false, // hard code for now
        },
        isLoading: true,
    };
    return [id, audienceDisplayFee];
};

export const createAudienceFeeSlice = (set): AudienceFeeState => ({
    audienceFeeIndex: new Map<string, AudienceDisplayFee>(),
    thirdPartyAudienceFeeIndex: new Map<string, ThirdPartyAudienceDisplayFee>(),
    thirdPartyImpressionFeeSupplyType: null,
    updateAudienceFeeIndex: (audiencesFeesToUpdate: Map<string, AudienceDisplayFee>) => set((state) => ({
        audienceFeeIndex: new Map([...state.audienceFeeIndex, ...audiencesFeesToUpdate])
    })),
    updateThirdPartyAudienceFeeIndex: (audiencesFeesToUpdate: Map<string, ThirdPartyAudienceDisplayFee>) => set((state) => ({
        thirdPartyAudienceFeeIndex: new Map([...state.thirdPartyAudienceFeeIndex, ...audiencesFeesToUpdate])
    })),
    observeAudiencesForPricing: (audiences: CustomElementSegment[]) => set((state) => {
        if (!audiences || audiences.length === 0)
            return state;
        const firstPartyAudiences = audiences
            .filter(audience => !THIRD_PARTY_CATEGORIES.includes(audience.category))
            .filter(audience => !state.audienceFeeIndex.has(audience.audienceId));
        const thirdPartyAudiences = audiences
            .filter(audience => THIRD_PARTY_CATEGORIES.includes(audience.category))
            .filter(audience => !state.thirdPartyAudienceFeeIndex.has(audience.audienceId));
        const thirdPartyAudiencesPriced = thirdPartyAudiences.map(audience => convertThirdPartyAudienceToPricedFee(audience, state.thirdPartyImpressionFeeSupplyType));
        return {
            audienceFeeIndex: new Map([...state.audienceFeeIndex, ...firstPartyAudiences.map(convertAudienceToEmptyFee)]),
            thirdPartyAudienceFeeIndex: new Map([...state.thirdPartyAudienceFeeIndex, ...thirdPartyAudiencesPriced]),
        }
    }),
    invalidateAudienceIndex: () => set((state) => ({
        audienceFeeIndex: new Map([...state?.audienceFeeIndex].map((entry) => {
            const [key, value] = entry;
            // TODO: If combined audiences, invalidate the 1p and 3p fees.
            return [key, {
                ...value,
                value: null,
                currency: null,
                range: null,
                isLoading: true
            }]
        }))
    })),
    setAudienceFeeLoadingState: (isLoading) => set((state) => ({
        audienceFeeIndex: new Map([...state?.audienceFeeIndex].map((entry) => {
            const [key, value] = entry;
            return [key, {
                ...value,
                isLoading: isLoading
            }]
        })),
    })),
    updateAudienceFromIndex: (audienceFee: AudienceDisplayFee | ThirdPartyAudienceDisplayFee) => set((state) => {
        if (THIRD_PARTY_CATEGORIES.includes(audienceFee?.audience?.category || "")) {
            return { thirdPartyAudienceFeeIndex: new Map([...state?.thirdPartyAudienceFeeIndex]).set(audienceFee?.audience?.id, audienceFee) }
        } else {
            return { audienceFeeIndex: new Map([...state?.audienceFeeIndex]).set(audienceFee.audience.id, audienceFee) }
        }
    }),
    updateThirdPartyImpressionSupplyType: (newImpressionSupplyType: FeeTypes) => set((state) => {
        const repricedThirdPartyAudiences = new Map<string, ThirdPartyAudienceDisplayFee>();
        state.thirdPartyAudienceFeeIndex.forEach((audience) => {
            repricedThirdPartyAudiences.set(audience.audience.audienceId, calculateThirdPartyAudienceFee(audience, newImpressionSupplyType));
        })
        return {
            thirdPartyImpressionFeeSupplyType: newImpressionSupplyType,
            thirdPartyAudienceFeeIndex: new Map(repricedThirdPartyAudiences),
        }
    })
})