import { createStore } from 'zustand'
import {
    AudienceFeeProps,
    AudienceFeeState,
    createAudienceFeeSlice
} from "src/store/AudienceFeeSlice";
import {
    createTechnologyFeeSlice,
    TechnologyFeeProps,
    TechnologyFeeState
} from "src/store/TechnologyFeeSlice";
import { logger } from "src/utils/StoreLogger";
import {
    CurrencyOfPreferenceFeeProps,
    createCurrencyOfPreferenceSlice,
    CurrencyOfPreferenceState
} from "src/store/CurrencyOfPreferenceFeeSlice";
import {
    ContextualFeeProps,
    ContextualFeeState,
    createContextualFeeSlice,
} from "src/store/ContextualFeeSlice";
import {
    createPerformancePlusSlice,
    PerformancePlusProps,
    PerformancePlusState
} from "src/store/PerformancePlusSlice";
import {
    createManagedServiceFeeSlice,
    ManagedServiceFeeProps,
    ManagedServiceFeeState
} from "src/store/MangedServiceFeeSlice";

export type FeeProps = AudienceFeeProps
    & TechnologyFeeProps
    & ContextualFeeProps
    & CurrencyOfPreferenceFeeProps
    & PerformancePlusProps
    & ManagedServiceFeeProps;
export type FeeState = AudienceFeeState
    & TechnologyFeeState
    & ContextualFeeState
    & CurrencyOfPreferenceState
    & PerformancePlusState
    & ManagedServiceFeeState;
export type FeeStore = ReturnType<typeof createFeeStore>;

export const createFeeStore = (initProps?: Partial<FeeProps>) => {
    const DEFAULT_PROPS: Partial<FeeProps> = {
        technologyFee: {
            isLoading: true,
            currency: null,
            range: null,
            value: null,
        },
        performancePlusFee: {
            isLoading: true,
            currency: null,
            range: null,
            value: null,
        },
        contextualProductFee: {
            isLoading: true,
            currency: null,
            range: null,
            value: null
        },
        contextualCategoryFee: {
            isLoading: true,
            currency: null,
            range: null,
            value: null
        },
        contextualRelatedProductFee: {
            isLoading: true,
            currency: null,
            range: null,
            value: null
        },
        contextualEntertainmentFee: {
            isLoading: true,
            currency: null,
            range: null,
            value: null
        },
        contextualKeywordFee: {
            isLoading: true,
            currency: null,
            range: null,
            value: null
        },
        managedServiceFee: {
            isLoading: true,
            currency: null,
            range: null,
            value: null
        },
    };

    return createStore<FeeState>()(
        logger(
        (set) => ({
                ...createAudienceFeeSlice(set),
                ...createTechnologyFeeSlice(set),
                ...createCurrencyOfPreferenceSlice(set),
                ...createContextualFeeSlice(set),
                ...createPerformancePlusSlice(set),
                ...createManagedServiceFeeSlice(set),
                ...DEFAULT_PROPS,
                ...initProps,
            }),
            'FeeStore'
        )
    )
}