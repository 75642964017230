var endpointMap = {
	ar: {
		strings: "translation-ar.json"
	},
	"ar-AE": {
		strings: "translation-ar.json"
	},
	de: {
		strings: "translation-de.json"
	},
	"de-DE": {
		strings: "translation-de.json"
	},
	en: {
		strings: "translation-en.json"
	},
	"en-AU": {
		strings: "translation-en.json"
	},
	"en-CA": {
		strings: "translation-en.json"
	},
	"en-GB": {
		strings: "translation-en.json"
	},
	"en-IN": {
		strings: "translation-en.json"
	},
	"en-SG": {
		strings: "translation-en.json"
	},
	"en-US": {
		strings: "translation-en.json"
	},
	es: {
		strings: "translation-es.json"
	},
	"es-CO": {
		strings: "translation-es.json"
	},
	"es-ES": {
		strings: "translation-es.json"
	},
	"es-MX": {
		strings: "translation-es.json"
	},
	"es-US": {
		strings: "translation-es.json"
	},
	fr: {
		strings: "translation-fr.json"
	},
	"fr-CA": {
		strings: "translation-fr.json"
	},
	"fr-FR": {
		strings: "translation-fr.json"
	},
	hi: {
		strings: "translation-hi.json"
	},
	"hi-IN": {
		strings: "translation-hi.json"
	},
	it: {
		strings: "translation-it.json"
	},
	"it-IT": {
		strings: "translation-it.json"
	},
	ja: {
		strings: "translation-ja.json"
	},
	"ja-JP": {
		strings: "translation-ja.json"
	},
	ko: {
		strings: "translation-ko.json"
	},
	"ko-KR": {
		strings: "translation-ko.json"
	},
	nl: {
		strings: "translation-nl.json"
	},
	"nl-NL": {
		strings: "translation-nl.json"
	},
	pl: {
		strings: "translation-pl.json"
	},
	"pl-PL": {
		strings: "translation-pl.json"
	},
	pt: {
		strings: "translation-pt.json"
	},
	"pt-BR": {
		strings: "translation-pt.json"
	},
	sv: {
		strings: "translation-sv.json"
	},
	"sv-SE": {
		strings: "translation-sv.json"
	},
	ta: {
		strings: "translation-ta.json"
	},
	"ta-IN": {
		strings: "translation-ta.json"
	},
	th: {
		strings: "translation-th.json"
	},
	"th-TH": {
		strings: "translation-th.json"
	},
	tr: {
		strings: "translation-tr.json"
	},
	"tr-TR": {
		strings: "translation-tr.json"
	},
	und: {
		strings: "translation.json"
	},
	vi: {
		strings: "translation-vi.json"
	},
	"vi-VN": {
		strings: "translation-vi.json"
	},
	zh: {
		strings: "translation-zh.json"
	},
	"zh-CN": {
		strings: "translation-zh.json"
	},
	"zh-TW": {
		strings: "translation-zh.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
